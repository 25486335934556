floatingMenu();
$(window).scroll(function() {
    floatingMenu();
});

innerHeight = window.innerHeight;

function floatingMenu(){
  if(!$("header").hasClass('gallery') && !$("header").hasClass('event')){
    if($(window).scrollTop() >= innerHeight - 150 ) {
        if($("header").hasClass("hidden")){
            $("header").removeClass("hidden");
            $("header nav").fadeIn();
        }
    }else{
          $("header").addClass("hidden");
          $("header nav").fadeOut();
    }
  }else if($("header").hasClass('event')){
    if($(window).scrollTop() >= innerHeight - 80 ) {
        if($("header").hasClass("transparent")){
            $("header").removeClass("transparent");
        }
    }else{
        $("header").addClass("transparent");
    }
  }
}


  var lastId,
  topMenu = $("header nav.main #main-menu ul"),
  topMenuHeight = topMenu.outerHeight()+20,
  menuItems = topMenu.find("a"),
  scrollItems = menuItems.map(function(){
    var item = $($(this).attr("href"));
    if (item.length) { return item; }
  });

  menuItems.click(function(e){
    var href = $(this).attr("href"),
        offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight+1;
    $('html, body').stop().animate({
        scrollTop: offsetTop
    }, 800);
    e.preventDefault();
});



// 2. This code loads the IFrame Player API code asynchronously.

var tag = document.createElement('script');

tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

// 3. This function creates an <iframe> (and YouTube player)
//    after the API code downloads.
var player;
function onYouTubeIframeAPIReady() {
  player = new YT.Player('player', {
    height: '1920',
    width: '1080',
    videoId: '1jJLGu-KUJU',
    playerVars: { 'controls': 0, 'modestbranding': 1, 'showinfo': 0, 'disablekb': 1  },
    events: {
      'onReady': onPlayerReady,
      'onStateChange': onPlayerStateChange
    }
  });
}

// 4. The API will call this function when the video player is ready.
function onPlayerReady(event) {
  $('.play-video').css('visibility', 'visible');
}

// 5. The API calls this function when the player's state changes.
//    The function indicates that when playing a video (state=1),
//    the player should play for six seconds and then stop.
var done = false;
function onPlayerStateChange(event) {
  if (event.data == YT.PlayerState.PLAYING && !done) {
    setTimeout(stopVideo, 6000);
    done = true;
  }
}
function stopVideo() {
  player.stopVideo();
}

$('.play-video').on('click', function(e) {
  $(".video-container iframe").css('visibility', 'visible');
    $(".video-container iframe")[0].src += "&autoplay=1";
    e.preventDefault();
});

$(document).ready(function () {

  var lazyLoadImages = new LazyLoad({
    elements_selector: ".logos img"
  });

  $('#contact form').submit(function(event) {

    event.preventDefault();

      $("#contact .contact-result").fadeOut();
      $("#contact .contact-loader").fadeIn();
      // $("button[type=submit]").text('Enviando...');
      $("button[type=submit]").attr('disabled', 'disabled');

      // name = $('input[name=name]').val();
      // email = $('input[name=email]').val();
      // phone = $('input[name=phone]').val();
      // message = $('textarea[name=message]').val();
      token = $("input[name=_token]").val();

      $.ajax({
          headers: {'X-CSRF-TOKEN': token},
          type        : 'POST',
          url         : baseUrl + '/contato',
          data        : $('#contact form').serialize(),
          dataType    : 'json', // what type of data do we expect back from the server
          encode          : true,
          success : function(data){
            $("#contact .contact-loader").fadeOut();
            $("#contact .contact-result").text(data.contact.message)
            if(data.contact.result == "1"){
              $("#contact .contact-result").removeClass("alert-danger");
              $("#contact .contact-result").addClass("alert-success");
            }else{
              $("#contact .contact-result").removeClass("alert-success");
              $("#contact .contact-result").addClass("alert-danger");
            }
            $('#contact form').trigger("reset");
            $("#contact .contact-result").fadeIn();
            grecaptcha.reset();
          },
          error : function(){
            $("#contact .contact-loader").fadeOut();
            $("#contact .contact-result").text("Erro ao enviar mensagem. Verifique o preenchimento do formulário.");
            $("#contact .contact-result").removeClass("alert-success");
            $("#contact .contact-result").addClass("alert-danger");
            $("#contact .contact-result").fadeIn();
            $("button[type=submit]").attr('disabled', false);
            // $("button[type=submit]").text('Enviar');
            grecaptcha.reset();
          }
      })

      event.preventDefault();
  });

	$("#category .gallery").lightGallery({
    download: false,
    share: false,
    thumbnail: true,
    exThumbImage: 'data-exthumbimage',
  });

    $('#gallery .gallery').slick({
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      autoplay: false,
      autoplaySpeed: 3000,
      responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        }
      }
      ]
    });

  $('#banner .background').slick({
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 1000,
      mobileFirst: true,
      fade: true,
  });

	$('#testimonials .gallery').slick({
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: false,
      autoplaySpeed: 4000,
      responsive: [
      {
        breakpoint: 992,
        settings: {
        }
      },
      {
        breakpoint: 768,
        settings: {
          // dots: true,
        }
      }
      ]
    });

	var PhoneMaskBehavior = function (val) {
		return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
	},
	spOptions = {
		onKeyPress: function(val, e, field, options) {
		    field.mask(PhoneMaskBehavior.apply({}, arguments), options);
		  }
	};

	$('input[name=phone]').mask(PhoneMaskBehavior, spOptions);

    if($('#home').length > 0){
      onScroll();
      $(document).on("scroll", function(){
        onScroll;
        floatingMenu();
      });
    }else{
      $(document).on("scroll", function(){
        floatingMenu();
      });
    }
		

		$('#home #banner a[href^="#"]').on('click', function (e) {
			e.preventDefault();
			$(document).off("scroll");

			$('nav ul li a').each(function () {
				$(this).parent().removeClass('active');
			})
			$(this).parent().addClass('active');

			var target = this.hash;
			$target = $(target);
			$('html, body').stop().animate({
				'scrollTop': $target.offset().top-100
			}, 500, 'swing', function () {
				window.location.hash = target;
				$(document).on("scroll", onScroll);
			});

			setTimeout(function(){
				$(document).on("scroll", function(){
					onScroll;
					floatingMenu();
				});
			}, 500);
		});
});

function onScroll(event = null){
  var scrollPosition = $(document).scrollTop();
  $('nav ul li a').each(function () {
    var currentLink = $(this);
    var refElement = $(currentLink.attr("href"));
    if (refElement.position().top-100 <= scrollPosition && refElement.position().top + refElement.height() > scrollPosition) {
      $('nav ul li a').parent().removeClass("active");
      currentLink.parent().addClass("active");
    }
  });
}
